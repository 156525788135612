import React from "react";
import StartFreeTrialButton from "../../components/StartFreeTrialButton";

const PricingContainer = () => {
  return (
    <div className="m-5">
      <div className="text-center mt-10">
        <h1 className="text-2xl font-semibold">
          Simple, transparent pricing for teams of all sizes!
        </h1>

        <div className="text-xl text-c-gray mt-4">
          Get all features for a single, predictable price and unlimited users
        </div>
      </div>

      <div className="flex justify-center mt-10">
        <div>
          <p className="text-5xl font-semibold text-center">$29 / month</p>

          <div className="flex flex-col items-center mt-4">
            <div className="text-xl text-c-gray mb-10">
              Free 14-day trial · No credit card required
            </div>

            <StartFreeTrialButton />
          </div>
        </div>
      </div>

      <div className="flex justify-center">
        <div className="w-3/4 mt-10 mb-10 text-center">
          <div>
            <span className="text-2xl font-semibold">Pricing Questions</span>
          </div>

          <div className="mt-8">
            <div className="flex flex-col text-left mt-2">
              <span className="text-xl text-c-purple">
                What happens when the trial is over? Will I be charged?
              </span>
              <span className="text-base mt-2 text-c-gray">
                After your trial ends you won&apos;t be charged because we
                don&apos;t require a credit card to try CodeKickBot. You&apos;ll
                receive a few messages during your trial prompting you to
                upgrade. If you don&apos;t upgrade and your trial expires,
                people will receive a response from CodeKickBot letting them
                know the trial is over.
              </span>
            </div>

            <div className="flex flex-col text-left mt-2">
              <span className="text-xl text-c-purple">
                How do you calculate the number of people on my team I have to
                pay for? Do I have to pay for everyone on my Slack team?
              </span>
              <span className="text-base mt-2 text-c-gray">
                There is no bar on the number of users using CodeKickBot. You
                pay $29 a month irrespective of the total number of users on
                your team.
              </span>
            </div>

            <div className="flex flex-col text-left mt-2">
              <span className="text-xl text-c-purple">
                Do you offer special discounts for educational institutions or
                not-for-profit organizations?
              </span>
              <span className="text-base mt-2 text-c-gray">
                Yes! If you are using CodeKickBot in a school, university, a
                not-for-profit organization or an open source community, contact
                us at support@codekickbot.com.
              </span>
            </div>

            <div className="flex flex-col text-left mt-2">
              <span className="text-xl text-c-purple">
                Do you have a refund policy?
              </span>
              <span className="text-base mt-2 text-c-gray">
                Yes, we have a 15 day money back guarantee if you are not
                satisfied with our services. Just contact us.
              </span>
            </div>

            <div className="flex flex-col text-left mt-2">
              <span className="text-xl text-c-purple">
                How do I cancel my subscription?
              </span>
              <span className="text-base mt-2 text-c-gray">
                Uninstall CodeKickBot from your Slack workspace and contact us
                at support@codekickbot.com to cancel your subscription. No
                questions asked!
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingContainer;
